const state = {
  menu:[],
  currentMenu: null,
  breadcrumb: null,
  tabsList: [],
  editableTabsValue: 'index'
}
const mutations = {
  selectMenu (state, val) {
    // if(val.name !== 'index'){
    state.breadcrumb = []
    const asideMenu = val.asideMenu
    for (const o in asideMenu) {
      // eslint-disable-next-line eqeqeq
      if (asideMenu[o].path == val.item.path) {
        state.breadcrumb.push(asideMenu[o])
      }
      const children = asideMenu[o].children
      for (const i in children) {
        // eslint-disable-next-line eqeqeq
        if (children[i].path == val.item.path) {
          state.breadcrumb.push(asideMenu[o])
          state.breadcrumb.push(children[i])
        }
      }
    }
    state.currentMenu = val.item
    state.editableTabsValue = val.item.name
    const result = state.tabsList.findIndex(item => item.name === val.item.name)
    // eslint-disable-next-line no-unused-expressions
    result === -1 ? state.tabsList.push(val.item) : ''
    // }else{
    //  state.currentMenu = null
    // }
    // val.name === 'index'? (state.currentMenu = null):(state.currentMenu = val)
  },
  closeTab (state, val) {
    const result = state.tabsList.findIndex(item => item.name === val.name)
    state.tabsList.splice(result, 1)
  },
  closeTab2 (state, name) {
    const result = state.tabsList.findIndex(item => item.name === name)
    state.tabsList.splice(result, 1)
  }
}

const actions = {
}
export default {
  state,
  mutations,
  actions
}
