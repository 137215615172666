
const state = {
    list:[
        'address-book',
        'adjust',
        'area-chart',
        'american-sign-language-interpreting',
        'audio-description',
        'university',
        'bars',
        'battery-empty',
        'battery-full',
        'battery-quarter',
        'bell',
        'bicycle',
        'bluetooth',
        'book',
        'briefcase',
        'bullhorn',
        'calculator',
        'calendar-o',
        'camera-retro',
        'caret-square-o-right',
        'cc',
        'check-circle-o',
        'circle',
        'clock-o',
        'cloud-download',
        'coffee',
        'comment-o',
        'comments-o',
        'credit-card',
        'cube',
        'database',
        'diamond',
        'envelope',
        'envelope-square',
        'exclamation-circle',
        'eye',
        'rss',
        'file-audio-o',
        'file-video-o',
        'file-powerpoint-o',
        'file-archive-o',
        'fire-extinguisher',
        'bolt',
        'folder-open',
        'gamepad',
        'gift',
        'users',
        'hand-peace-o',
        'bed',
        'hourglass-end',
        'hourglass-start',
        'id-card-o',
        'info',
        'keyboard-o',
        'gavel',
        'life-ring',
        'lightbulb-o',
        'low-vision',
        'reply',
        'map-marker',
        'meh-o',
        'minus',
        'mobile',
        'graduation-cap',
        'bars',
        'paint-brush',
        'pencil',
        'phone',
        'pie-chart',
        'plus-circle',
        'power-off',
        'question',
        'quote-right',
        'registered',
        'reply-all',
        'search-minus',
        'server',
        'share-square',
        'shopping-bag',
        'sign-in',
        'sign-language',
        'snowflake-o',
        'sort-alpha-desc',
        'sort-desc',
        'sort-asc',
        'square',
        'star-half-o',
        'sticky-note',
        'sun-o',
        'tag',
        'television',
        'thermometer-quarter',
        'thermometer-empty',
        'thermometer-three-quarters',
        'thumbs-o-up',
        'times-circle',
        'tint',
        'toggle-on',
        'trash',
        'truck',
        'universal-access',
        'sort',
        'user-circle-o',
        'user-times',
        'video-camera',
        'volume-up',
        'wifi',
        'window-minimize',
        'address-book-o',
        'american-sign-language-interpreting',
        'arrows',
        'assistive-listening-systems',
        'car',
        'bar-chart',
        'bath',
        'battery-quarter',
        'battery-empty',
        'battery-three-quarters',
        'bell-o',
        'binoculars',
        'bluetooth-b',
        'bookmark',
        'bug',
        'bullseye',
        'calendar',
        'calendar-plus-o',
        'caret-square-o-up',
        'certificate',
        'check-square',
        'circle-o',
        'clone',
        'cloud-upload',
        'cog',
        'commenting',
        'compass',
        'credit-card-alt',
        'cubes',
        'deaf',
        'dot-circle-o',
        'pencil-square-o',
        'envelope-o',
        'eraser',
        'exclamation-triangle',
        'eye-slash',
        'female',
        'file-code-o',
        'file-pdf-o',
        'film',
        'flag',
        'flask',

    ]
}
const mutations = {
}
const actions = {
}
  
export default {
state,
mutations,
actions
}