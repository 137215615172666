// 转小写
const lower = value => value.toLowerCase()
// 转大写
const upper = value => value.toUpperCase()
const currencyStyle = (value, style) => { // 货币格式
  /**
   * style:
   *   currency：货币
   *   number： 数字格式
   */
  // eslint-disable-next-line eqeqeq
  if (style == 'number') { // 数字格式
    return parseFloat(value.replace(/[^\d.-]/g, ''))
  } else { // 货币格式, 并保留2位小数
    const n = 2
    value = parseFloat((value + '').replace(/[^\d.-]/g, '')).toFixed(n) + ''
    const l = value.split('.')[0].split('').reverse()
    const r = value.split('.')[1]
    let t = ''
    for (let i = 0; i < l.length; i++) {
      // eslint-disable-next-line eqeqeq
      t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? ',' : '')
    }
    return t.split('').reverse().join('') + '.' + r
  }
}
export {
  lower,
  upper,
  currencyStyle
}
