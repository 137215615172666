<template>
  <footer>
    <div class="left">
      <a class="font-w600" href="http://www.AppDeno.com/" target="_blank">AppDeno</a>   © 2020
    </div>
    <div class="right">
      Crafted with  by <a class="font-w600" href="http://www.zooir.com/" target="_blank">zooir</a>
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style scoped lang="scss">
  footer{
    font-size: 12px;
    font-weight: 800;
    .left{
      float: left;
      margin-left: 10px;
    }
    .right{
      text-align: right;
      margin-right: 10px;
    }
  }
</style>
