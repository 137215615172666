<template>
  <div class="tags">
    <el-tabs v-model="tabsValue" type="card" @edit="closeTabs" @tab-click="changeMenu">
      <el-tab-pane
              v-for="(tag,index) in tabs"
              :key="'tabs-'+index"
              :closable="tag.name !== 'index'"
              :label="tag.label"
              :name="tag.name">
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapState({
      tabs: state => state.tab.tabsList,
      menu: state => state.tab.menu
    }),
    tabsValue: {
      get () {
        return this.$store.state.tab.editableTabsValue
      },
      set (v) {
        this.$store.state.tab.editableTabsValue = v
      }
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    ...mapMutations({
      close: 'closeTab',
      close2: 'closeTab2'
    }),
    closeTabs (name, action) {
      if (action === 'remove') {
        // eslint-disable-next-line no-unused-vars
        const tabs = this.tabs
        this.close2(name)
      }
    },
    changeMenu (tab, event) {
      const tabs = this.tabs
      this.tabsValue = tab.name
      this.$router.push({ name: tab.name })
      const result = tabs.findIndex(item => item.name === tab.name)

      const data = {
        item: tabs[result],
        asideMenu: this.menu
      }
      this.$store.commit('selectMenu', data)
    }
  },
  components: {
  }
}
</script>

<style>
.tags .el-tabs--card>.el-tabs__header{
    border-bottom: 0;
    margin:0;
    padding: 3px 10px 3px 10px;
}
.tags .el-tabs--card>.el-tabs__header .el-tabs__item{
    height:32px;
    line-height: 32px;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-right: 5px;
    font-weight: 1;
    background-color: #fff;
    color: #808695;
}
.tags .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: 0;
}
.tags .el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
  color:#2d8cf0;
}
.tags .el-tabs--card>.el-tabs__header .el-tabs__item .el-icon-close:hover{
    background-color:#2d8cf0 !important;
}

</style>
<style scoped lang="scss">
    .tags{
      width:100%;
      height: 40px;
      margin: 50px 0 0 0;
      padding: 3px 0 0 0;
      position: fixed;
      background: #f5f7f9;
      z-index: 1000;
    }
</style>
