'use strict'
import Vue from 'vue'
import Store from './store'
import Cookie from './cookie'

const Plugin = {}
let AppletKit

// eslint-disable-next-line prefer-const
AppletKit = {
  // 获取URL参数
  get_url_params (that, name) {
    if (!that) {
      return "warning: The getUrlParams(this) function is missing the 'this' parameter"
    }
    let parameter
    const query = that.$route.query
    const params = that.$route.params
    if (name) {
      parameter = query[name]
      // eslint-disable-next-line eqeqeq
      if (parameter == undefined || parameter == '') {
        parameter = params[name]
      }
    } else {
      parameter = query
      if (!parameter) {
        parameter = params
      }
    }
    return parameter
  },
  // 也很简单
  timestamp(strtime){
    var date = new Date(strtime)
    let time1 = date.getTime()
    return time1
  },
  // 计算时间
  timeDifference(startTime, stepTime, endTime) {
    let start1 = startTime.split(":")
    let startAll = parseInt(start1[0]*60) + parseInt(start1[1])
    let end1 = endTime.split(":")
    let endAll = parseInt(end1[0]*60) + parseInt(end1[1])
    let step1 = stepTime.split(":")
    step1 = parseFloat(parseInt(step1[0])+'.'+parseInt(step1[1]))*100
    let stepDb = (endAll-startAll) / step1
    let hour = start1[0]
    let branch = start1[1]
    let list = []
    if(stepDb > 0 && stepDb != 'Infinity') {
      for (let i = 0; i<stepDb; i++) {
        let sdate1 = new Date(1900, 1, 1, hour, branch);
        sdate1.setMinutes(sdate1.getMinutes() + 30)
        let now = sdate1.getHours() + ":" + sdate1.getMinutes()
        if (sdate1.getMinutes() === 0) {
          now = sdate1.getHours() + ":00"
        }
        if (i == 0) {
          list[0] = startTime
        }
        list[i + 1] = now
        let nowtime = now.split(":")
        hour = nowtime[0]
        branch = nowtime[1]
      }
    }
    return list
  },
  //时间戳转换方法    date:时间戳数字
  formatDate(date) {
    date = new Date(date);
    let YY = date.getFullYear() + '-';
    let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    return YY + MM + DD;
  },
  // 日期时间的格式化
  date_format (fmt = 'Y-m-d H:I:S', date = new Date()) {
    const opt = {
      'Y+': date.getFullYear().toString(), // 年
      'm+': (date.getMonth() + 1).toString(), // 月
      'd+': date.getDate().toString(), // 日
      'H+': date.getHours().toString(), // 时
      'I+': date.getMinutes().toString(), // 分
      'S+': date.getSeconds().toString() // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (let k in opt) {
      let ret = new RegExp('(' + k + ')').exec(fmt)
      if (ret) {
        // eslint-disable-next-line eqeqeq
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
      };
    };
    return fmt
  },
  unixToTime(fmt,UnixTime) {
    let a = UnixTime*1000//.replace("/Date(", "").replace(")/", "");
    let date = new Date(parseInt(a));
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    let minute = date.getMinutes();
    let second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    const opt = {
      'Y+': y, // 年
      'm+': m, // 月
      'd+': d, // 日
      'H+': h, // 时
      'I+': minute, // 分
      'S+': second // 秒
    }
    for (let k in opt) {
      let ret = new RegExp('(' + k + ')').exec(fmt)
      if (ret) {
        // eslint-disable-next-line eqeqeq
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
      }
    }
    return fmt
  },
  // 检测数组中是否存在某个字符串
  in_array (search, array) {
    for (const i in array) {
      // eslint-disable-next-line eqeqeq
      if (array[i] == search) {
        return true
      }
    }
    return false
  },
  // JSON转URL参数
  json_to_url (data) {
    const tempArr = []
    for (const i in data) {
      const key = encodeURIComponent(i)
      const value = encodeURIComponent(data[i])
      tempArr.push(key + '=' + value)
    }
    const urlParamsStr = tempArr.join('&')
    if (!urlParamsStr) {
      return ''
    }
    return urlParamsStr
  },
  // URL参数转JSON
  url_to_json (url) {
    url = url.match(/\?([^#]+)/)[1]
    const obj = {}; const arr = url.split('&')
    for (let i = 0; i < arr.length; i++) {
      const subArr = arr[i].split('=')
      const key = decodeURIComponent(subArr[0])
      const value = decodeURIComponent(subArr[1])
      obj[key] = value
    }
    if (!obj) {
      return ''
    }
    return obj
  },
  Cookie,
  Store

}

Plugin.install = function (Vue, options) {
  Vue.prototype.AppletKit = AppletKit
  Vue.prototype.$atk = AppletKit
}

Vue.use(Plugin)

export default Plugin
