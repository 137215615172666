import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index/index.vue')
  },

  {
    path: '/admin/member',
    name: 'admin:member',
    component: () => import('@/views/admin/Member.vue')
  },
  {
    path: '/admin/recycle',
    name: 'admin:recycle',
    component: () => import('@/views/admin/Recycle.vue')
  },
  {
    path: '/admin/logs/action',
    name: 'admin:logs:action',
    component: () => import('@/views/admin/logs/action.vue')
  },
  {
    path: '/admin/logs/login',
    name: 'admin:logs:login',
    component: () => import('@/views/admin/logs/login.vue')
  },

  {
    path: '/auth/group',
    name: 'auth:group',
    component: () => import('@/views/auth/Group.vue')
  },
  {
    path: '/auth/menu',
    name: 'auth:menu',
    component: () => import('@/views/auth/Menu.vue')
  },
  {
    path: '/auth/rule',
    name: 'auth:rule',
    component: () => import('@/views/auth/Rule.vue')
  },

  // {
  //   path: '/apps/my',
  //   name: 'apps:my',
  //   component: () => import('@/views/apps/my.vue')
  // },
  // {
  //   path: '/apps/store',
  //   name: 'apps:store',
  //   component: () => import('@/views/apps/store.vue')
  // },
  // {
  //   path: '/apps/run',
  //   name: 'apps:run',
  //   component: () => import('@/views/apps/run.vue')
  // },

  // {
  //   path: '/clouds/images',
  //   name: 'clouds:images',
  //   component: () => import('@/views/clouds/Images.vue')
  // },
  // {
  //   path: '/clouds/videos',
  //   name: 'clouds:videos',
  //   component: () => import('@/views/clouds/Videos.vue')
  // },
  // {
  //   path: '/clouds/recycle',
  //   name: 'clouds:recycle',
  //   component: () => import('@/views/clouds/Recycle.vue')
  // },
  // {
  //   path: '/clouds/document',
  //   name: 'clouds:document',
  //   component: () => import('@/views/clouds/Document.vue')
  // },
  // {
  //   path: '/clouds/music',
  //   name: 'clouds:music',
  //   component: () => import('@/views/clouds/Music.vue')
  // },

  {
    path: '/crontab/item',
    name: 'crontab:item',
    component: () => import('@/views/crontab/Item.vue')
  },

  // {
  //   path: '/setting/system',
  //   name: 'setting:system',
  //   component: () => import('@/views/setting/System.vue')
  // },
  // {
  //   path: '/setting/objectStorageService',
  //   name: 'setting:objectStorageService',
  //   component: () => import('@/views/setting/ObjectStorageService.vue')
  // },
  // {
  //   path: '/setting/message',
  //   name: 'setting:message',
  //   component: () => import('@/views/setting/Message.vue')
  // },
  // {
  //   path: '/setting/bindingDeno',
  //   name: 'setting:bindingDeno',
  //   component: () => import('@/views/setting/BindingDeno.vue')
  // },


  // 門店面板
  {
    path: '/booking/storePanel/queryCustomer',
    name: 'booking:storePanel:queryCustomer',
    component: () => import('@/views/booking/storePanel/queryCustomer.vue')
  },
  {
    path: '/booking/storePanel/booking',
    name: 'booking:storePanel:booking',
    component: () => import('@/views/booking/storePanel/booking.vue')
  },
  {
    path: '/booking/storePanel/customer',
    name: 'booking:storePanel:customer',
    component: () => import('@/views/booking/storePanel/customer.vue')
  },
  {
    path: '/booking/storePanel/seatInquiry',
    name: 'booking:storePanel:seatInquiry',
    component: () => import('@/views/booking/storePanel/seatInquiry.vue')
  },

  // 團隊面板
  {
    path: '/booking/teamPanel/queryCustomer',
    name: 'booking:teamPanel:queryCustomer',
    component: () => import('@/views/booking/teamPanel/queryCustomer.vue')
  },
  {
    path: '/booking/teamPanel/booking',
    name: 'booking:teamPanel:booking',
    component: () => import('@/views/booking/teamPanel/booking.vue')
  },
  {
    path: '/booking/teamPanel/seatInquiry',
    name: 'booking:teamPanel:seatInquiry',
    component: () => import('@/views/booking/teamPanel/seatInquiry.vue')
  },


  // 管理面板
  {
    path: '/booking/adminPanel/queryCustomer',
    name: 'booking:adminPanel:queryCustomer',
    component: () => import('@/views/booking/adminPanel/queryCustomer.vue')
  },
  {
    path: '/booking/adminPanel/config',
    name: 'booking:adminPanel:config',
    component: () => import('@/views/booking/adminPanel/config.vue')
  },
  {
    path: '/booking/adminPanel/goods',
    name: 'booking:adminPanel:goods',
    component: () => import('@/views/booking/adminPanel/goods.vue')
  },
  {
    path: '/booking/adminPanel/invoice',
    name: 'booking:adminPanel:invoice',
    component: () => import('@/views/booking/adminPanel/invoice.vue')
  },
  {
    path: '/booking/adminPanel/customer',
    name: 'booking:adminPanel:customer',
    component: () => import('@/views/booking/adminPanel/customer.vue')
  },
  {
    path: '/booking/adminPanel/seatInquiry',
    name: 'booking:adminPanel:seatInquiry',
    component: () => import('@/views/booking/adminPanel/seatInquiry.vue')
  },
  {
    path: '/booking/adminPanel/invoiceReport',
    name: 'booking:adminPanel:invoiceReport',
    component: () => import('@/views/booking/adminPanel/invoiceReport.vue')
  },



  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/error/403.vue')
  },
  { /* Not Found 路由，必须是最后一个路由 */
    path: '*',
    component: () => import('@/views/error/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: 'admin',
  routes
})

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
