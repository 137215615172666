import Vue from 'vue'
import Vuex from 'vuex'
import tab from './tab'
import user from './user'
import icon from './icon'
Vue.use(Vuex)



export default new Vuex.Store({
  state: {
    menu:{
      isCollapse:false
    },
    denoStatus:false,
    userInfo:{}
  },
  modules: {
    tab,
    user,
    icon
  }
})
