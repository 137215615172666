import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vDialogs from 'v-dialogs'
import StoreDB from './plugins/store.js'
import './plugins/appletKit.js'
import './plugins/axios'
import './plugins/element.js'
import './utils/http.js'
import 'font-awesome/css/font-awesome.min.css'
import * as filters from './filters/filters.js'

Vue.use(vDialogs)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false





// 不需要路由验证页面
const userInfo = StoreDB.store.get('userInfo') || {}
store.state.userInfo = userInfo
const whiteList = ['login', 'v1','403','404']

router.beforeEach((to, from, next) => {
  // 确定用户是否已登录
  if (userInfo.token) {
    if (to.path === '/login') {
      // 如果已登录，请重定向到主页
      next({ path: '/index' })
      return
    }
    if (whiteList.indexOf(to.name) !== -1) {
      // 在免费登录白名单中，直接进入
      next()
    } else {
      let menuPath = StoreDB.store.get('getMenuList').menu
      let pathData =[]
      for(let m in menuPath){
        if(menuPath[m].linkType === "0"){
          pathData.push(menuPath[m].path)
        }
        if(menuPath[m].children){
          let menuPath2 = menuPath[m].children
          for(let m2 in menuPath2){
            if(menuPath2[m2].linkType === "0"){
              pathData.push(menuPath2[m2].path)
            }
            if(menuPath2[m2].children){
              let menuPath3 = menuPath2[m2].children
              for(let m3 in menuPath3){
                if(menuPath3[m3].linkType === "0"){
                  pathData.push(menuPath3[m3].path)
                }
              }
            }
          }
        }
      }
      if(!StoreDB.store.in_array(to.path,pathData)){
        //store.state.validationStatus = false
        next({ path: '/403' })
        return
      }
    }


    // // 获取菜单栏
    // let data = StoreDB.store.get('getMenuList')
    // if(data == undefined){
    //   ApiGetMenuList('/v1/auth/getMenuBarList')
    //   setTimeout(() => {
    //     data = Store.store.get('getMenuList')
    //     store.state.tab.menu =  data.menu
    //     store.state.tab.tabsList = data.tabsList
    //   }, 500);
    // }else{
    //   store.state.tab.menu =  data.menu
    //   store.state.tab.tabsList = data.tabsList
    // }


    next()
  } else {
    if (whiteList.indexOf(to.name) !== -1) {
      // 在免费登录白名单中，直接进入
      next()
    } else {
      // 没有访问权限的其他页将重定向到登录页。
      next({ path: '/login' })
    }
  }
})







new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
