'use strict'
import Vue from 'vue'
import axios from 'axios'
import Store from '../plugins/store'
import md5 from 'js-md5'
const Plugin = {}
// eslint-disable-next-line prefer-const
let _http
const userInfo = Store.store.get('userInfo')
let headers = {}
let permissionCheck
let uploadByPieces

// 检查登录授权
const checkLoginAuth = function(errorCode){
  // 
  switch(errorCode){
    case 401: // 登录已过期
      Store.store.remove('userInfo')
    break;
  }
  return 
}

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  }
)

if (userInfo) {
  headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userInfo.token
    }
  }
}

// eslint-disable-next-line prefer-const
permissionCheck = {
  http (val = {}) {
    const errcode = val.errcode || 0
    // eslint-disable-next-line eqeqeq
    if (errcode == 401) {
      Store.store.remove('userInfo')
      history.go(0)
    }
  }
}

// 分片上传
/*
* 分片上传函数 支持多个文件
* @param options
* options.file 表示源文件
* options.pieceSize 表示需要分片的大小 默认是5m
* options.chunkUrl 分片上传的后端地址
* options.fileUrl 整个文件的上传地址
* progress 进度回调
* success 成功回调
* error 失败回调
*
*/
// eslint-disable-next-line prefer-const
uploadByPieces = ({ files, chunkUrl, fileUrl, pieceSize = 5, progress, success, error }) => {
  if (!files || !files.length) return

  // 上传过程中用到的变量
  const fileList = [] // 总文件列表
  let progressNum = 1 // 进度
  let successAllCount = 0 // 上传成功的片数
  // let currentAllChunk = 0  // 当前上传的片数索引
  let AllChunk = 0 // 所有文件的chunk数之和
  let AllFileSize = 0 // 所有文件size

  // 获取md5
  const readFileMD5 = (files) => {
    // 读取每个文件的md5
    files.map((file, index) => {
      const fileRederInstance = new FileReader()
      fileRederInstance.readAsBinaryString(file)
      fileRederInstance.addEventListener('load', e => {
        const fileBolb = e.target.result
        const fileMD5 = md5(fileBolb)
        if (!fileList.some((arr) => arr.md5 === fileMD5)) {
          fileList.push({ md5: fileMD5, name: file.name, file })
          AllFileSize = AllFileSize + file.size
        }
        if (index === files.length - 1) readChunkMD5(fileList)
      }, false)
    })
  }
  const getChunkInfo = (file, currentChunk, chunkSize) => {
    const start = currentChunk * chunkSize
    const end = Math.min(file.size, start + chunkSize)
    const chunk = file.slice(start, end)
    return { start, end, chunk }
  }

  // 针对每个文件进行chunk处理
  const readChunkMD5 = (fileList) => {
    fileList.map((currentFile, fileIndex) => {
      const chunkSize = pieceSize * 1024 * 1024 // 5MB一片
      const chunkCount = Math.ceil(currentFile.file.size / chunkSize) // 总片数
      AllChunk = AllChunk + chunkCount // 计算全局chunk数
      // let fileSize = currentFile.file.size // 文件大小
      // 针对单个文件进行chunk上传
      for (let i = 0; i < chunkCount; i++) {
        const currentChunk = i
        const { chunk } = getChunkInfo(currentFile.file, i, chunkSize)
        const chunkFR = new FileReader()
        chunkFR.readAsBinaryString(chunk)
        chunkFR.addEventListener('load', e => {
          const chunkBolb = e.target.result
          const chunkMD5 = md5(chunkBolb)
          // this.readingFile = false
          // currentFile
          uploadChunk(currentFile, { chunkMD5, chunk, currentChunk, chunkCount }, fileIndex)
        }, false)
      }
    })
  }

  // 更新进度
  const progressFun = () => {
    progressNum = Math.ceil(successAllCount / AllChunk * 100)
    progress(progressNum)
  }

  // 对分片已经处理完毕的文件进行上传
  const uploadFile = (currentFile) => {
    const makeFileForm = new FormData()
    makeFileForm.append('shardingMd5', currentFile.fileMD5)
    makeFileForm.append('shardingName', currentFile.name)
    // 合并文件
    _http.POST(fileUrl, makeFileForm, res => {
      progressFun()
      res.file_name = currentFile.name
      success && success(res)
      successAllCount++
    }, e => {
      error && error(e)
    })
  }
  const uploadChunk = (currentFile, chunkInfo, fileIndex) => {
    const fetchForm = new FormData()
    fetchForm.append('shardingName', currentFile.name)
    fetchForm.append('shardingMd5', currentFile.fileMD5)
    fetchForm.append('shardingData', chunkInfo.chunk)
    fetchForm.append('shardingTotal', chunkInfo.chunkCount)
    fetchForm.append('shardingChunk', chunkInfo.currentChunk)
    fetchForm.append('shardingChunkMd5', chunkInfo.chunkMD5)
    _http.POST(chunkUrl, fetchForm, res => {
      progressFun()
      // currentAllChunk++
      if (chunkInfo.currentChunk < chunkInfo.chunkCount - 1) {
        successAllCount++
      } else {
        // 当总数大于等于分片个数的时候
        if (chunkInfo.currentChunk >= chunkInfo.chunkCount - 1) {
          uploadFile(currentFile, fileIndex)
        }
      }
    }, (e) => {
      error && error(e)
    })
  }
  // 开始执行代码
  readFileMD5(files)
}

_http = {
  GET (Url, success, fail) {
    _axios.get(Url, headers).then(res => {
      checkLoginAuth(res.data.errcode)
      permissionCheck.http(res.data)
      return typeof success === 'function' && success(res.data)
    }).catch(err => {

      return typeof fail === 'function' && fail(err)
    })
  },
  POST (Url, data = {}, success, fail) {
    _axios.post(Url, data, headers).then(res => {
      checkLoginAuth(res.data.errcode)
      permissionCheck.http(res.data)
      return typeof success === 'function' && success(res.data)
    }).catch(err => {
      return typeof fail === 'function' && fail(err)
    })
  },
  DELETE (Url, success, fail) {
    // headers.data = data
    _axios.delete(Url, headers).then(res => {
      checkLoginAuth(res.data.errcode)
      permissionCheck.http(res.data)
      return typeof success === 'function' && success(res.data)
    }).catch(err => {
      return typeof fail === 'function' && fail(err)
    })
  },
  PUT (Url, data = {}, success, fail) {
    _axios.put(Url, data, headers).then(res => {
      checkLoginAuth(res.data.errcode)
      permissionCheck.http(res.data)
      return typeof success === 'function' && success(res.data)
    }).catch(err => {
      return typeof fail === 'function' && fail(err)
    })
  },
  UPLOAD (data = {}) {
    uploadByPieces(data)
  }
}


// 获取菜单栏目列表
export function ApiGetMenuList(url) {
  _http.GET(url,(res)=>{
    if (res.errcode == 0) {
      let list = res.data||[]
      let data = {
        menu:list,
        tabsList:[list[0]]
      }
      Store.store.set('getMenuList',data)
    }
  })
}






Plugin.install = function (Vue, options) {
  Vue.prototype.$http = _http
}

Vue.use(Plugin)


export default Plugin


// export default Plugin
