<template>
  <div class="header">
    <el-row :gutter="24">
      <el-col :span="12">
        <div class="header-li collapseBtn" @click="collapseBtn">
          <div :class="[isCollapse?'el-icon-s-unfold':'el-icon-s-fold']"></div>
        </div>
        <div class="header-li refresh" @click="refreshBtn()">
          <i class="fa fa-refresh"></i>
        </div>
        <div class="header-li breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首頁</el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item,index) in breadcrumb" :key="index">
              <b v-if="index>=1">{{item.label}}</b>
              <span v-else>{{item.label}}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>

      <el-col :span="12">
        <div style="float: right;">
          <div class="header-li expand" @click="handleFullScreen">
            <i :class="[fullscreen?'fa fa-compress':'fa fa-expand']"></i>
          </div>

          <div class="header-li userHeader">
            <el-dropdown @command="signOutBtn">
              <span class="el-dropdown-link">
                <img :src="userInfo.avatarUrl?userInfo.avatarUrl:avatarUrl" />
                {{userInfo.name}}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="changePassword">修改密碼</el-dropdown-item>
                <el-dropdown-item command="signOut" divided>登出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-col>
    </el-row>


    <el-dialog
      title="修改密碼"
      :modal="false"
      :visible.sync="changePasswordStatus"
      width="500"
      :before-close="changePasswordClose">
      <el-form :model="changePasswordForm" :rules="changePasswordRules" ref="changePasswordForm" label-width="80px">
        <el-form-item label="原始密碼" prop="passwordOld">
          <el-input v-model="changePasswordForm.passwordOld"></el-input>
        </el-form-item>
        <el-form-item label="新密碼" prop="password">
          <el-input v-model="changePasswordForm.password"></el-input>
        </el-form-item>
        <el-form-item label="確認密碼" prop="passwordConfirm">
          <el-input v-model="changePasswordForm.passwordConfirm"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="changePasswordFormLoading"
            size="medium"
            style="float: right; margin-bottom: 12px;"
            @click="upPassword">確定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>

</template>

<script>
import { mapState } from 'vuex'
import imgHeadUrl from '@/assets/img/admin/user/1.jpg'
export default {
  computed: {
    ...mapState({
      breadcrumb: (state) => state.tab.breadcrumb,
      userInfo: (state) => state.userInfo
    })
  },
  inject: ['reload'],
  data () {
    return {
      changePasswordStatus:false,
      aside: 224,
      isCollapse: false,
      fullscreen: false,
      avatarUrl:imgHeadUrl,
      changePasswordFormLoading:false,
      changePasswordForm:{
        passwordOld:'',
        password:'',
        passwordConfirm:''
      },
      changePasswordRules:{
        passwordOld: [
          { required: true, message: '请输入原始密碼', trigger: 'blur' },
          { min: 6, message: '长度不能少于 6 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新密碼', trigger: 'blur' },
          { min: 6, message: '长度不能少于 6 个字符', trigger: 'blur' }
        ],
        passwordConfirm: [
          { required: true, message: '请输入確認密碼', trigger: 'blur' },
          { min: 6, message: '长度不能少于 6 个字符', trigger: 'blur' }
        ],
      }
    }
  },
  mounted () {
    const that = this
    that.isCollapse = this.$atk.Store.store.get('isCollapse')
    this.$store.state.menu.isCollapse = this.isCollapse
  },
  methods: {
    collapseBtn () {
      this.isCollapse = !this.isCollapse
      this.$store.state.menu.isCollapse = this.isCollapse
      this.$atk.Store.store.set('isCollapse', this.isCollapse)
    },
    refreshBtn () {
      this.reload()
    },
    signOutBtn (val) {
      // eslint-disable-next-line eqeqeq
      if (val == 'signOut') {
        this.$atk.Store.store.remove('userInfo')
        this.$atk.Store.store.remove('getMenuList')
        window.location.href="/login";
      } else if(val == 'changePassword'){
        this.changePasswordStatus = true
      }
    },
    changePasswordClose(done){
      done()
    },
    handleFullScreen () {
      const element = document.documentElement
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      this.fullscreen = !this.fullscreen
    },
    // 重置
    resetForm(){
      this.changePasswordForm = {
        passwordOld:'',
        password:'',
        passwordConfirm:''
      }
    },
    // 更新密碼
    upPassword(){
      let that = this
      that.$refs.changePasswordForm.validate((valid) => {
        if (valid) {
          that.changePasswordFormLoading = true;
          let form = {
            ...that.changePasswordForm,
          }
          if(form.password != form.passwordConfirm){
            that.$message({
              message: '新密碼與確認密碼不一致。',
              type: 'warning'
            })
            return
          }
          that.$http.PUT('/v1/admin/upPassword',form, (ret)=> {
              that.changePasswordFormLoading = false;
              if (ret.errcode == 0) {
                that.resetForm()
                that.signOutBtn('signOut')
                that.$message.success(ret.errmsg);
              } else if (ret.errcode >= 1) {
                that.$message({
                  message: ret.errmsg,
                  type: 'warning'
                })
              }
            },(err)=> {
              that.changePasswordFormLoading = false;
              that.$notify({
                title: '警告',
                message: err,
                type: 'warning'
              })
            }
          )
        }
      });
    }
  },
  watch: {
    isCollapse () {
      if (this.isCollapse) {
        this.aside = 64
      } else {
        this.aside = 224
      }
    }
  }
}
</script>
<style scoped lang="scss">
.header {
  width: 100%;
  height: 50px;
  background-color: #fff;
  position: fixed;
  z-index: 1002;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .header-li {
    float: left;
  }
  .collapseBtn {
    margin-top: 13px;
    padding-left: 10px;
    div {
      color: $default;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .refresh {
    margin-top: 15px;
    padding-left: 20px;
    font-size: 13px;
    cursor: pointer;
  }
  .breadcrumb {
    margin: 18px 20px;
  }
  .expand {
    padding-top: 13px;
    right: 10px;
    cursor: pointer;
  }
  .userHeader {
    padding: 12px 20px 0 50px;
  }

  .el-dropdown {
    cursor: pointer;
    img {
      position: absolute;
      width: 28px;
      height: 28px;
      margin: -3px -32px;
      border-radius: 50%;
    }
  }
}
</style>
