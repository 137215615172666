<template>
  <div id="app">
    <!-- <router-view/> -->
    <VerifyPage v-if="$store.state.userInfo.token">
    </VerifyPage>
    <NoVerifyPage v-else>
    </NoVerifyPage>


  </div>
</template>
<script>
import {ApiGetMenuList} from '@/utils/http'
import VerifyPage from '@/views/panel/verifyPage'
import NoVerifyPage from '@/views/panel/NoVerifyPage'
import '@/assets/css/style.css'
export default {
  name: 'app',
  data () {
    return {
    }
  },
  beforeCreate() {
    let that = this;
    // 获取菜单列表
    if(that.$store.state.userInfo.token){
      let data = that.$atk.Store.store.get('getMenuList')
      if(data == undefined){
        ApiGetMenuList('/v1/auth/getMenuBarList')
        let sh = setInterval(()=>{
          data = that.$atk.Store.store.get('getMenuList')
          if(data) {
            that.$store.state.tab.menu = data.menu
            that.$store.state.tab.tabsList = data.tabsList
            clearInterval(sh);
            window.location.href = "/";
          }
        },100)
      }else{
        that.$store.state.tab.menu =  data.menu
        that.$store.state.tab.tabsList = data.tabsList
      }
    }
  },
  mounted(){
    //this.$dlg.alert('Data saved successfully!')
    // this.$dlg.alert('Data saved successfully!', function(){
    //   // dialog close callback
    // })
  },
  components: {
    VerifyPage,
    NoVerifyPage,
  }
}
</script>
