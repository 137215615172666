<template>
  <div class="app-wrapper">
    <el-container style="height: 100%">
      <MenuView class="sidebar-container"></MenuView>
      <main
        class="main-container"
        :style="{marginLeft:[isCollapse?'64px':'200px'],width:[isCollapse?'calc(100% - 64px)':'calc(100% - 200px)']}"
      >
        <HeaderView :style="{width:[isCollapse?'calc(100% - 64px)':'calc(100% - 200px)']}"></HeaderView>
        <MainTagView :style="{width:[isCollapse?'calc(100% - 64px)':'calc(100% - 200px)']}"></MainTagView>
        <div class="main-router">
          <router-view v-if="isRouterAlive"></router-view>
        </div>
      </main>
      <FooterView
        class="footer-container"
        :style="{marginLeft:[isCollapse?'64px':'200px'],width:[isCollapse?'calc(100% - 64px)':'calc(100% - 200px)']}"
      ></FooterView>
    </el-container>
  </div>
</template>

<script>
  import HeaderView from '../../components/Header'
  import MenuView from '../../components/Menu'
  import FooterView from '../../components/Footer'
  import MainTagView from '../../components/MainTag'
export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isCollapse: false,
      isRouterAlive: true
    }
  },
  mounted () {
    const that = this
    that.isCollapse = that.$atk.Store.store.get('isCollapse')
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
  components: {
    HeaderView,
    MenuView,
    FooterView,
    MainTagView
  },
  watch: {
    '$store.state.menu.isCollapse': function () {
      const that = this
      that.isCollapse = that.$store.state.menu.isCollapse
    }
  }
}
</script>
<style scoped lang="scss">
.main-container {
  min-height: 100%;
  transition: margin-left 0.3s;
  position: relative;
  .main-router {
    margin-top: 91px;
    height: calc(100% - 130px);
    padding: 0px 10px 0px 10px;
    background: #f5f7f9;
  }
}
.footer-container {
  width: 100%;
  height: 23px;
  padding-top: 10px;
  transition: margin-left 0.3s;
  background-color: #fff;
  bottom: 0px;
  position: fixed;
  box-shadow: 2px 0 6px rgba(209, 209, 209, 0.35);
  z-index: 1000;
}
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
</style>
