<template>
  <el-aside class="menu-wrapper" :style="{width:[isCollapse?'64px':'200px'],height:'100%'}">
    <div class="sidebar-logo-container">
      <a href="/" class="sidebar-logo-link router-link-active">
        <img src="../assets/img/admin/logo-2021-04.png" class="sidebar-logo" />
<!--        <h1 class="sidebar-title">Rx美容集團</h1>-->
      </a>
    </div>

    <el-scrollbar>

      <el-menu :default-active="current?current.path:$route.path"
        class="el-menu-oiid"
        text-color="hsla(0,0%,100%,.7)"
        style="border-right: 0;"
        :unique-opened="true"
        :collapse="isCollapse">

            <div class="menuBg" v-for="(item1,index1) in menuIsNav" :key="'tab-2-'+index1">

                <el-submenu :index="item1.path" v-if="item1.children && item1.children.length>0">
                    <template slot="title">
                        <i :class="item1.icon"></i>
                        <span style="margin-left:6px;" v-if="!isCollapse"> {{item1.label}}</span>
                    </template>

                    <div class="subMenuBg" v-for="(item2,index2) in item1.children" :key="'tab-2-2-'+index2">
                      <el-submenu :index="item2.path" v-if="item2.children && item2.children.length>0">

                        <template slot="title">
                          <i :class="item2.icon"></i>
                          <span style="margin-left:6px;"> {{item2.label}}</span>
                        </template>

                        <el-menu-item :index="item3.path" v-for="(item3,index3) in item2.children" :key="'tab-2-3-'+index3" @click="clickMenu(item3)">
                          <i :class="item3.icon"></i>
                          <span style="margin-left:6px;"> {{item3.label}}</span>
                        </el-menu-item>

                      </el-submenu>

                      <el-menu-item :index="item2.path" v-else  @click="clickMenu(item2)" :key="'tab-2-1-'+index1">
                        <i :class="item2.icon"></i>
                        <span style="margin-left:6px;"> {{item2.label}}</span>
                      </el-menu-item>
                    </div>

                </el-submenu>

                <el-menu-item :index="item1.path" v-else @click="clickMenu(item1)" :key="'tab-1-'+index1">
                  <i :class="item1.icon"></i>
                  <span style="margin-left:6px;" v-if="!isCollapse"> {{item1.label}}</span>
                </el-menu-item>

            </div>
        </el-menu>


    </el-scrollbar>
  </el-aside>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      current: (state) => state.tab.currentMenu,
      menu: (state) => state.tab.menu
    }),
    // 菜单过滤
    menuIsNav () {
      let newList = [];
      let list = this.menu;
      for (let o in list) {
        if (list[o].children[0]) {
          let children = list[o].children;
          let newchildren = [];
          for (let i in children) {
            if(parseInt(children[i].isNav) == 1){
              let children2 = children[i].children;
              let newchildren2 = [];
              for (let j in children2) {
                if(parseInt(children2[j].isNav) == 1){
                  newchildren2.push({
                    ...children2[j],
                  });
                }
              }
              newchildren.push({
                ...children[i],
                children: newchildren2,
              });
            }
          }
          if(parseInt(list[o].isNav) == 1) {
            newList.push({
              ...list[o],
              children: newchildren,
            });
          }
        } else {
          if(parseInt(list[o].isNav) == 1) {
            newList.push({
              ...list[o]
            });
          }
        }
      }
      return newList
    },
    // hasChildren () {
    //   return this.menu.filter((item) => item.children)
    // }
  },
  data () {
    return {
      isCollapse: false,
      pathMenu: null
    }
  },
  mounted () {
    const that = this
    that.pathMenu = that.$route.path
    setTimeout(function () {
      that.tabMenuBtn()
    }, 100)
    that.isCollapse = that.$atk.Store.store.get('isCollapse')
  },
  methods: {
    tabMenuBtn () {
      const that = this
      const path = that.$route.path
      const lists = that.menu
      let item = {}
      if (path) {
        for (const o in lists) {
          // eslint-disable-next-line eqeqeq
          if (lists[o].path == path && lists[0].name != 'index') {
            item = lists[o]
          }
          if (!item.path) {
            const children = lists[o].children
            for (const i in children) {
              // eslint-disable-next-line eqeqeq
              if (children[i].path == path && children[i].name != 'index') {
                item = children[i]
              }
            }
          }
        }
        if (item.path) {
          that.clickMenu(item)
        }
      }
    },
    clickMenu (item) {
      const data = {
        item: item,
        asideMenu: this.menu
      }
      if(item.linkType=='1'){
        window.open(item.path);
      }else{
        this.$store.commit('selectMenu', data)
        this.$router.push({ name: item.name })
      }
    }
  },
  watch: {
    '$store.state.menu.isCollapse': function () {
      const that = this
      that.isCollapse = that.$store.state.menu.isCollapse
    },
  }
}
</script>
<style scoped lang="scss">
.sidebar-container {
  transition: width 0.3s;
  background-color: #191a23;
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);

  // reset element-ui css
  .horizontal-collapse-transition {
    transition: 0s width ease-in-out, 0s padding-left ease-in-out,
      0s padding-right ease-in-out;
  }

  .scrollbar-wrapper {
    overflow-x: hidden !important;
  }
  .el-scrollbar__bar.is-vertical {
    right: 0px;
  }
  .el-scrollbar {
    height: 100%;
  }
  &.has-logo {
    .el-scrollbar {
      height: calc(100% - 50px);
    }
  }
  .is-horizontal {
    display: none;
  }
  a {
    display: inline-block;
    width: 100%;
    overflow: hidden;
  }
  .svg-icon {
    margin-right: 16px;
  }

  // menu hover
  .el-menu {
    border: none;
    height: 100%;
    width: 100% !important;
    .el-menu-item-group /deep/ .el-menu-item-group__title {
      padding: 0 !important;
    }
  }
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #191a23;
  text-align: center;
  overflow: hidden;
  border-bottom: 1px solid #101117;
  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 120px;
      height: 28px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 17px;
      //font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
