'use strict'

let cookie
// eslint-disable-next-line prefer-const
cookie = {
  // 写cookies
  set (name, value) {
    const Days = 30
    const exp = new Date()
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
    document.cookie = name + '=' + escape(value) + ';expires=' + exp.toGMTString()
  },
  // 读取cookies
  get (name) {
    let arr
    const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    // eslint-disable-next-line no-cond-assign
    if (arr = document.cookie.match(reg)) {
      return unescape(arr[2])
    } else {
      return null
    }
  },
  // 删除cookies
  del (name) {
    const exp = new Date()
    exp.setTime(exp.getTime() - 1)
    const value = this.get(name)
    if (value != null) {
      document.cookie = name + '=' + value + ';expires=' + exp.toGMTString()
    }
  }
}
export default {
  cookie: cookie
}
